import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4eab8504"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_4 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_7 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_8 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_9 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_10 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_11 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_12 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_13 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_14 = { for: "currentDietSupplementDescription" }
const _hoisted_15 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_16 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_17 = { for: "currentDietAllergyDescription" }
const _hoisted_18 = { class: "p-field p-col-6 p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelectBox = _resolveComponent("MultiSelectBox")!
  const _component_MultiCheckBox = _resolveComponent("MultiCheckBox")!
  const _component_HealzInput = _resolveComponent("HealzInput")!

  return (_ctx.surveyCgmInitialFormDto)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDiet",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDiet,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDiet) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietOptions,
              title: _ctx.$t('personData.currentDiet.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietDescription",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietDescription,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietDescription) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietDescriptionOptions,
              title: _ctx.$t('personData.currentDietDescription.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietNumberOfMeals",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietNumberOfMeals,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietNumberOfMeals) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietNumberOfMealsOptions,
              title: _ctx.$t('personData.currentDietNumberOfMeals.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietSnack",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietSnack,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietSnack) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietSnackOptions,
              title: _ctx.$t('personData.currentDietSnack.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietSnackDescription",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietSnackDescription,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietSnackDescription) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietSnackDescriptionOptions,
              title: _ctx.$t('personData.currentDietSnackDescription.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietFast",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietFast,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietFast) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietFastOptions,
              title: _ctx.$t('personData.currentDietFast.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietFastHours",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietFastHours,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietFastHours) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietFastHoursOptions,
              title: _ctx.$t('personData.currentDietFastHours.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietDigestiveProblems",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietDigestiveProblems,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietDigestiveProblems) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietDigestiveProblemsOptions,
              title: _ctx.$t('personData.currentDietDigestiveProblems.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_MultiCheckBox, {
              ref: "refCurrentDietDigestiveProblemsDescription",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietDigestiveProblemsDescription,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietDigestiveProblemsDescription) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietDigestiveProblemsDescriptionOptions,
              title: _ctx.$t('personData.currentDietDigestiveProblemsDescription.title'),
              type: "diseaseDescription"
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietSupplement",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietSupplement,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietSupplement) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietSupplementOptions,
              title: _ctx.$t('personData.currentDietSupplement.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('personData.currentDietSupplementDescription.title')), 1),
            _createVNode(_component_HealzInput, {
              id: "currentDietSupplementDescription",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietSupplementDescription,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietSupplementDescription) = $event)),
              editable: _ctx.editMode,
              type: "text"
            }, null, 8, ["modelValue", "editable"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietAllergy",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietAllergy,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietAllergy) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietAllergyOptions,
              title: _ctx.$t('personData.currentDietAllergy.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('personData.currentDietAllergyDescription.title')), 1),
            _createVNode(_component_HealzInput, {
              id: "currentDietAllergyDescription",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietAllergyDescription,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietAllergyDescription) = $event)),
              editable: _ctx.editMode,
              type: "text"
            }, null, 8, ["modelValue", "editable"])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refCurrentDietWaterQuantity",
              modelValue: _ctx.surveyCgmInitialFormDto.currentDietWaterQuantity,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.currentDietWaterQuantity) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.currentDietWaterQuantityOptions,
              title: _ctx.$t('personData.currentDietWaterQuantity.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}